import React from 'react';
import '../styles.css';
import {Typography, makeStyles, Grid, CssBaseline} from '@material-ui/core';
import {KeyboardArrowDownRounded} from '@material-ui/icons';
import Background from './Background';
import LogoHome from './LogoHome';
import Menu from './NavigationMenu';

const useStyle = makeStyles({
    arrowFontSize: {
        fontSize: '4rem',
        '@media screen and (max-device-width: 700px)': {
            fontSize: '3rem'
        }
    },
    text: {
        fontSize: 'var(--landing-font)',
        fontFamily: 'var(--landing-font-family)',
        fontWeight: '300',
        textAlign: 'center',
        '@media screen and (max-width: 1280px), (max-device-width: 1280px)': {
            fontSize: 'var(--landing-font-medium)'
        },
        '@media screen and (max-width: 960px), (max-device-width: 960px)': {
            fontSize: 'var(--landing-font-small)'
        },
        '@media screen and (max-width: 600px), (max-device-width: 850px)': {
            fontSize: 'var(--landing-font-xsmall)'
        },
        '@media screen and (max-device-width: 650px) and (orientation: portrait), (max-device-width: 600px), (max-device-height: 300px)': {
            fontSize: '10px'
        }
    },
    item: {
        padding: '0 20px'
    },
    lastLine: {
        '@media screen and (max-width: 550px), (max-device-width: 850px)': {
            marginBottom: '20vh'
        }
    }
});

const Home = () => {
    const classes = useStyle();

    const headline_text = 'REIMAGINE  HOW  YOU  CONNECT,  EVALUATE, & MEASURE  EVERYTHING'.split('');

    return (
        <>
            <CssBaseline/>

            <Menu/>

            <Background/>

            <Grid container alignItems={'center'} className={'home-container'}>
                <Grid container item xs={12} className={'centered-text'}>
                    <Grid item xs={12}>
                        <LogoHome/>
                    </Grid>
                </Grid>

                <Grid container item xs={12}>
                    <Grid item xs={12} classes={{item: classes.item}}>
                        <Typography classes={{body1: classes.text}}>
                            {headline_text.map((el, i) => (
                                <span key={`${el}_${i}`}
                                      className={'animate-flicker'}
                                      style={{
                                          animationDelay: `${i * 500}ms`,
                                          margin: '0 5px'
                                      }}>
                                {el}
                            </span>
                            ))}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item xs={12} className={'centered-text'} classes={{root: classes.lastLine}}>
                    <Grid item xs={12}>
                        <a href={'/solutions'}>
                            <KeyboardArrowDownRounded className={'down-arrow'} fontSize={'large'}
                                                      classes={{fontSizeLarge: classes.arrowFontSize}}/>
                        </a>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
};

export default Home;