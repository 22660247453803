import React from 'react';
import {makeStyles, Typography} from '@material-ui/core';
import '../styles.css';

const useStyle = makeStyles({
    text: {
        fontSize: 'var(--landing-font)',
        fontFamily: 'var(--landing-font-family)',
        fontWeight: '300',
        textAlign: 'center',
        '@media screen and (max-width: 960px), (max-device-width: 960px)': {
            fontSize: 'var(--landing-font-medium)'
        }
    }
});

const ComingSoon = () => {
    const classes = useStyle();

    const coming_soon = 'COMING SOON'.split('');

    return (
        <>
            <div id={'comingsoon'}>
                <div className={'landing'}>
                    <Typography classes={{body1: classes.text}}>
                        {coming_soon.map((el, i) => (
                            <span key={`${el}_${i}`}
                                  className={'animate-flicker'}
                                  style={{
                                      animationDelay: `${i * 500}ms`,
                                      margin: '0 5px'
                                  }}>
                                {el}
                            </span>
                        ))}
                    </Typography>
                </div>
            </div>
        </>
    );
};

export default ComingSoon;