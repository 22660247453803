import React, {useRef, useState} from 'react';
import {
    Button, Link, makeStyles, MenuItem, useMediaQuery, useTheme, Menu, Box, List
} from '@material-ui/core';
import {Menu as MenuIcon} from '@material-ui/icons';
import normalizeCaps from '../services/normalizeCaps';
import MenuDropDown from "./MenuDropDown";

const useStyle = makeStyles({
    menuPaper: {
        backgroundColor: 'var(--menu-background)',
        color: 'var(--text)',
        fontFamily: 'var(--tabs-font-family)'
    }
});

const NavigationMenu = () => {
    const classes = useStyle();
    const theme = useTheme();
    const is_under_medium = useMediaQuery(theme.breakpoints.down('md'));

    const location = useRef();
    location.current = window.location.pathname;

    const [anchorEl, setAnchorEl] = useState(undefined);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    const menu = ['SERVICES', 'SOLUTIONS', 'SUPPORT', 'ABOUT US', 'CONTACT US'];

    return (
        <>
            {is_under_medium ?
                <Box style={{textAlign: 'right', zIndex: 999999}}>
                    <Button aria-controls='menu' aria-haspopup='true' onClick={handleClick} style={{zIndex: 999999}}>
                        <MenuIcon fontSize={'large'} style={{color: 'var(--text)'}}/>
                    </Button>

                    <Menu
                        id='menu'
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        classes={{paper: classes.menuPaper}}
                        style={{opacity: 0.9, zIndex: 9999}}
                    >
                        {
                            menu.map(item => (
                                <MenuItem key={item}>
                                    {item !== 'ABOUT US' ?
                                        <Link href={`/${normalizeCaps(item)}`} style={{
                                            color: 'var(--text)',
                                            textDecoration: 'none',
                                            fontFamily: 'var(--tabs-font-family)'
                                        }}>
                                            {item}
                                        </Link>
                                        :
                                        <MenuDropDown type={'collapsed'}/>
                                    }
                                </MenuItem>
                            ))
                        }
                    </Menu>
                </Box>
                :
                <Box style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}>
                    <List style={{
                        position: 'absolute',
                        backgroundColor: 'transparent',
                        zIndex: 9999
                    }}>
                        {menu.map((el, i) => (
                            el !== 'ABOUT US' ?
                                <Link key={`list_${i}`} href={`/${normalizeCaps(el)}`} style={{
                                    color: 'var(--text)',
                                    textDecoration: 'none',
                                    margin: '0 50px',
                                    fontFamily: 'var(--tabs-font-family)'
                                }}
                                      data-link={window.location.pathname.substr(1) === normalizeCaps(el)}>
                                    {el}
                                </Link>
                                :
                                <MenuDropDown key={`list_${i}`} type={'wide'}/>
                        ))}
                    </List>
                </Box>
            }
        </>
    );
};

export default NavigationMenu;