import React, {useRef, useState} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {
    FormControl, InputLabel, Input, makeStyles, Grid, Paper, TextField, Button, Select, MenuItem, Typography, Link
} from '@material-ui/core';
import '../styles.css';
import Navigation from './Navigation';
import {Alert, AlertTitle} from '@material-ui/lab';
import {PhoneRounded, MailOutlineRounded, LinkedIn} from '@material-ui/icons';

const useStyles = makeStyles({
    header: {
        fontFamily: 'var(--landing-font-family)',
        fontWeight: 100,
        '@media screen and (max-width: 1280px), (max-device-width: 1280px)': {
            fontSize: '2.5rem'
        },
        '@media screen and (max-device-width: 400px)': {
            fontSize: '2rem'
        }
    },
    subheader: {
        fontFamily: 'var(--landing-font-family)',
        fontWeight: 100,
        '@media screen and (max-width: 1280px), (max-device-width: 1280px)': {
            fontSize: '1rem'
        },
        '@media screen and (max-device-width: 400px)': {
            fontSize: '0.8rem'
        }
    },
    text: {
        fontFamily: 'var(--tabs-font-family)',
        fontWeight: 100,
        fontSize: '1.3rem',
        '@media screen and (max-width: 1280px), (max-device-width: 1280px)': {
            fontSize: '0.9rem'
        },
        '@media screen and (max-device-width: 400px)': {
            fontSize: '0.65rem'
        }
    },
    link: {
        color: 'var(--text)'
    },
    icon: {
        fontSize: '2.5rem',
        '@media screen and (max-width: 1280px), (max-device-width: 1280px)': {
            fontSize: '2rem'
        },
        '@media screen and (max-device-width: 400px)': {
            fontSize: '1.5rem'
        }
    },
    formText: {
        fontFamily: 'var(--tabs-font-family)',
        color: 'var(--text)',
        marginLeft: '10px',
        '&:focus': {
            color: 'var(--text)'
        },
        '&.Mui-focused': {
            color: 'var(--text)'
        },
        '@media screen and (max-device-width: 400px)': {
            fontSize: '0.7rem'
        }
    },
    root: {
        '& .MuiFormLabel-root': {
            color: 'var(--text)',
            fontFamily: 'var(--tabs-font-family)',
            marginLeft: '10px',
            '@media screen and (max-device-width: 400px)': {
                fontSize: '0.7rem'
            }
        },
        '& .MuiInputBase-root': {
            color: 'var(--text)',
            marginLeft: '10px'
        },
        '& .MuiInputBase-input': {
            width: '95%'
        },
        '& .MuiPaper-root': {
            color: 'var(--text)',
            background: 'var(--grey-background)'
        },
        '& .Mui-error': {
            color: 'var(--green-accent)'
        },
        '& .Mui-error::after': {
            borderBottomColor: 'var(--green-accent)'
        },
        '& .MuiFormHelperText-root': {
            marginLeft: '5px'
        }
    },
    underline: {
      width: '95%',
      '&:after': {
            borderBottomColor: 'var(--text)'
      }
    },
    selectPaper: {
        color: 'var(--text)',
        background: 'var(--grey-background)'
    },
    paper: {
        backgroundColor: 'transparent',
        padding: '20px',
        width: '80%',
        color: 'var(--text)',
        '@media screen and (min-device-width: 1020px) and (orientation: landscape)': {
            top: '55%'
        },
        '@media screen and (max-width: 1280px), (max-device-width: 1280px)': {
            width: '80%'
        },
        '@media screen and (max-width: 600px), (max-device-width: 600px)': {
            width: '100%'
        },
        '@media screen and (max-device-width: 600px) and (orientation: portrait)': {
            top: '65%'
        },
        '@media screen and (max-device-width: 300px) and (orientation: portrait)': {
            top: '60%'
        },
        '@media screen and (min-device-width: 400px) and (orientation: portrait)': {
            top: '55%'
        },
        '@media screen and (min-device-width: 300px) and (max-device-width: 399px) and (orientation: portrait)': {
            top: '65%'
        },
        '@media screen and (min-device-width: 350px) and (max-device-width: 399px) and (orientation: portrait)': {
            top: '60%'
        },
        '@media screen and (max-device-width: 960px) and (orientation: landscape)': {
            top: '100%'
        },
        '@media screen and (max-device-width: 750px) and (orientation: landscape)': {
            top: '70%'
        },
        '@media screen and (max-device-width: 740px) and (orientation: landscape)': {
            top: '95%'
        },
        '@media screen and (max-device-width: 720px) and (orientation: landscape)': {
            top: '75%'
        },
        '@media screen and (max-device-width: 670px) and (orientation: landscape)': {
            top: '105%'
        },
        '@media screen and (max-device-width: 660px) and (orientation: landscape)': {
            top: '135%'
        },
        '@media screen and (max-device-width: 650px) and (orientation: landscape)': {
            top: '105%'
        },
        '@media screen and (max-device-width: 600px) and (orientation: landscape)': {
            top: '120%'
        }
    },
    downArrow: {
        color: 'var(--text)'
    },
    inputBase: {
        '& .MuiInputBase-root': {
            color: 'var(--text)',
            marginLeft: '10px'
        }
    },
    button: {
        border: '3px solid var(--text)',
        borderRadius: '4px',
        color: 'var(--text)',
        fontFamily: 'var(--landing-font-family)',
        textShadow: '1px 1px #e0e0e0',
        padding: '10px 5px',
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        backgroundImage: 'linear-gradient(-180deg, var(--light-green) 0%, var(--green-accent) 100%)',
        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
    },
    alertSuccess: {
        backgroundColor: 'var(--green-accent)',
        position: 'relative',
        top: '10vh',
        width: '50%',
        margin: 'auto',
        fontFamily: 'var(--tabs-font-family)',
        boxShadow: '0 0 5px 10px rgba(12, 23, 19, .2)',
        '@media screen and (min-device-width: 400px)': {
            width: '80%',
            top: '10vh'
        },
        '@media screen and (max-device-width: 1400px) and (orientation: landscape)': {
            top: '10vh'
        },
        '@media screen and (min-device-width: 1100px) and (max-device-width: 1350px)': {
            width: '80%',
            top: '2vh'
        },
        '@media screen and (max-device-width: 1025px) and (orientation: landscape)': {
            top: '2vh'
        },
        '@media screen and (min-device-width: 401px) and (max-device-width: 850px)': {
            width: '80%',
            top: '2vh'
        },
        '@media screen and (max-width: 960px), (max-device-width: 960px)': {
            top: '10vh',
            width: '80%'
        },
        '@media screen and (max-device-width: 825px)': {
            top: '2vh',
            width: '80%'
        },
        '@media screen and (max-width: 600px), (max-device-width: 600px)': {
            width: '100%',
            fontSize: '0.8rem',
            top: '2vh'
        }
    },
    alertError: {
        color: 'var(--text)',
        backgroundColor: '#CF4530',
        position: 'relative',
        width: '50%',
        margin: 'auto',
        fontFamily: 'var(--tabs-font-family)',
        boxShadow: '0 0 5px 10px rgba(12, 23, 19, .2)',
        '@media screen and (min-device-width: 400px)': {
            width: '80%',
            top: '10vh'
        },
        '@media screen and (max-device-width: 1400px) and (orientation: landscape)': {
            top: '10vh'
        },
        '@media screen and (min-device-width: 1100px) and (max-device-width: 1350px)': {
            width: '80%',
            top: '2vh'
        },
        '@media screen and (max-device-width: 1025px) and (orientation: landscape)': {
            top: '2vh'
        },
        '@media screen and (min-device-width: 401px) and (max-device-width: 850px)': {
            width: '80%',
            top: '2vh'
        },
        '@media screen and (max-width: 960px), (max-device-width: 960px)': {
            top: '10vh',
            width: '80%'
        },
        '@media screen and (max-device-width: 825px)': {
            top: '2vh',
            width: '80%'
        },
        '@media screen and (max-width: 600px), (max-device-width: 600px)': {
            width: '100%',
            fontSize: '0.8rem',
            top: '2vh'
        }
    },
    alertMessage: {
        '@media screen and (max-width: 600px), (max-device-width: 600px)': {
            fontSize: '0.7rem'
        },
        '@media screen and (max-width: 400px), (max-device-width: 400px)': {
            fontSize: '0.5rem'
        }
    },
    contactContainer: {
        '@media screen and (min-width: 960px)': {
            top: '25vh'
        },
        '@media screen and (max-width: 1280px) and (max-height: 890px)': {
            top: '20vh'
        },
        '@media screen and (max-width: 960px) and (max-height: 890px), (min-device-width: 1023px) and (max-device-width: 1280px) and (orientation: landscape)': {
            top: '5vh'
        }
    },
    lastLine: {
        marginBottom: '10vh'
    }
});

const ContactUs = () => {
        const recaptcha_ref = useRef();
        const classes = useStyles();

        const [contact_form_data, setContactFormData] = useState({
            firstName: '',
            lastName: '',
            companyName: '',
            email: '',
            phone: '',
            reason: '',
            message: ''
        });

        const [alert, setAlert] = useState(false);
        const [success, setSuccess] = useState(false);
        const [is_submit_displayed, setIsSubmitDisplayed] = useState(false);

        const handleReCaptchaChange = () => {
            setIsSubmitDisplayed(true);
        };

        const handleSubmit = async (e) => {
            e.preventDefault();

            fetch('https://ggxn5oiuv8.execute-api.us-east-2.amazonaws.com/v1/contactus', {
                method: 'post',
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(contact_form_data)
            })
                .then(res => res.json())
                .then(json => {
                    if (json.statusCode === 200) {
                        setContactFormData({
                            firstName: '',
                            lastName: '',
                            companyName: '',
                            email: '',
                            phone: '',
                            reason: '',
                            message: ''
                        });
                        recaptcha_ref.current.reset();
                        setAlert(true);
                        setSuccess(true);
                    } else {
                        setAlert(true);
                        setSuccess(false);
                    }
                })
                .catch(err => console.log('postContactForm', err));
        };

        return (
            <>
                <Navigation/>

                {alert && (success ?
                    <Alert severity={'success'}
                           classes={{standardSuccess: classes.alertSuccess, message: classes.alertMessage}}>
                        <AlertTitle>Success</AlertTitle>
                        Thank you for contacting us. We'll reach you out soon
                    </Alert>
                    :
                    <Alert severity={'error'}
                           classes={{standardError: classes.alertError, message: classes.alertMessage}}>
                        <AlertTitle>Error</AlertTitle>
                        Something went wrong. Please check the form fields
                    </Alert>)
                }

                <Grid container className={'grid-container'} classes={{root: classes.contactContainer}}>
                    <Grid container item xs={12} md={6} justifyContent={'center'}>
                        <Grid item xs={12}>
                            <Typography variant={'h3'} align={'center'} component={'header'}
                                        classes={{root: classes.header}}>
                                CONTACT US
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant={'h5'} align={'center'} component={'h2'}
                                        classes={{root: classes.subheader}}>
                                to find out how to evolve your IoT into an IoE network
                            </Typography>
                        </Grid>

                        <Grid item xs={6} style={{marginTop: '50px'}}>
                            <Typography align={'center'} component={'div'} classes={{root: classes.text}}
                                        paragraph className={'contact-info'}>
                                <PhoneRounded classes={{root: classes.icon}}/>
                                <p>+1-888-620-0119</p>
                            </Typography>

                            <Typography align={'center'} component={'div'} classes={{root: classes.text}}
                                        paragraph className={'contact-info'}>
                                <MailOutlineRounded classes={{root: classes.icon}}/> <p>support@vigilantioe.com</p>
                            </Typography>

                            <Typography align={'center'} component={'div'} classes={{root: classes.text}}
                                        paragraph className={'contact-info'}>
                                <MailOutlineRounded classes={{root: classes.icon}}/> <p>sales@vigilantioe.com</p>
                            </Typography>

                            <Typography align={'center'} component={'div'} className={'contact-info'}
                                        style={{justifyContent: 'center'}}>
                                <Link href={'https://www.linkedin.com/company/vigilantioe/'}
                                      TypographyClasses={{colorPrimary: classes.link}}>
                                    <LinkedIn classes={{root: classes.icon}}/>
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} md={6} justifyContent={'center'} classes={{root: classes.lastLine}}>
                        <Grid item xs={12}>
                            <ValidatorForm onSubmit={handleSubmit} style={{display: 'flex', justifyContent: 'center'}}>
                                <Paper elevation={3} classes={{root: classes.paper}}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <FormControl className={'form-control'} fullWidth required>
                                                <InputLabel htmlFor='first-name' classes={{root: classes.formText}}>
                                                    First Name
                                                </InputLabel>

                                                <Input id='first-name' name={'firstName'} classes={{root: classes.formText, underline: classes.underline}}
                                                       value={contact_form_data.firstName}
                                                       onChange={e => setContactFormData({
                                                           ...contact_form_data,
                                                           firstName: e.target.value
                                                       })}/>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <FormControl className={'form-control'} fullWidth required>
                                                <InputLabel htmlFor='last-name' classes={{root: classes.formText}}>
                                                    Last Name
                                                </InputLabel>

                                                <Input id='last-name' name={'lastName'} classes={{root: classes.formText, underline: classes.underline}}
                                                       value={contact_form_data.lastName}
                                                       onChange={e => setContactFormData({
                                                           ...contact_form_data,
                                                           lastName: e.target.value
                                                       })}/>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextValidator id='email'
                                                           name={'email'}
                                                           InputProps={{classes: {root: classes.formText, underline: classes.underline}}}
                                                           InputLabelProps={{root: classes.formText}}
                                                           className={[classes.root, 'form-control'].join(' ')}
                                                           label={'Email'}
                                                           fullWidth
                                                           required
                                                           value={contact_form_data.email}
                                                           onChange={e => setContactFormData({
                                                               ...contact_form_data,
                                                               email: e.target.value
                                                           })}
                                                           validators={['isEmail']}
                                                           errorMessages={['Please match the pattern xxx@xxx.xxx']}/>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <FormControl className={'form-control'} fullWidth>
                                                <InputLabel htmlFor='company-name' classes={{root: classes.formText}}>
                                                    Company Name
                                                </InputLabel>

                                                <Input id='company-name' name={'companyName'}
                                                       classes={{root: classes.formText, underline: classes.underline}}
                                                       value={contact_form_data.companyName}
                                                       onChange={e => setContactFormData({
                                                           ...contact_form_data,
                                                           companyName: e.target.value
                                                       })}/>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextValidator id='phone'
                                                           name={'phone'}
                                                           InputProps={{classes: {root: classes.formText, underline: classes.underline}}}
                                                           InputLabelProps={{root: classes.formText}}
                                                           className={[classes.root, 'form-control'].join(' ')}
                                                           label={'Phone'}
                                                           fullWidth
                                                           required
                                                           value={contact_form_data.phone}
                                                           onChange={e => setContactFormData({
                                                               ...contact_form_data,
                                                               phone: e.target.value
                                                           })}
                                                           validators={['matchRegexp:^[0-9]*$']}
                                                           errorMessages={['Please only use numbers']}/>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <FormControl className={[classes.root, 'form-control'].join(' ')} fullWidth
                                                         required>
                                                <InputLabel id='reason'>Reason to contact us</InputLabel>
                                                <Select
                                                    labelId='reason'
                                                    id='reason'
                                                    value={contact_form_data.reason.toLowerCase()}
                                                    onChange={e => setContactFormData({
                                                        ...contact_form_data,
                                                        reason: e.target.value
                                                    })}
                                                    classes={{icon: classes.downArrow}}
                                                    MenuProps={{classes: {paper: classes.selectPaper}}}
                                                >
                                                    <MenuItem value={'sales'}>
                                                        Sales
                                                    </MenuItem>

                                                    <MenuItem value={'support'}>
                                                        Support
                                                    </MenuItem>

                                                    <MenuItem value={'sales'}>
                                                        Request a Virgil IoE monitoring demo
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField id='message'
                                                       name={'message'}
                                                       InputProps={{classes: {root: classes.formText, underline: classes.underline}}}
                                                       InputLabelProps={{root: classes.formText}}
                                                       className={[classes.root, 'form-control'].join(' ')}
                                                       multiline
                                                       rows={'8'}
                                                       label={'Message'}
                                                       fullWidth
                                                       required
                                                       value={contact_form_data.message}
                                                       onChange={e => setContactFormData({
                                                           ...contact_form_data,
                                                           message: e.target.value
                                                       })}/>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <ReCAPTCHA sitekey={'6LfWJoMbAAAAADIwzkK3GGQxar4OT_fLSloNPl_w'}
                                                       ref={recaptcha_ref}
                                                       size={'normal'}
                                                       onChange={handleReCaptchaChange}
                                                       theme={'dark'}
                                                       style={{
                                                           display: 'flex',
                                                           justifyContent: 'center'
                                                       }}
                                            />
                                        </Grid>

                                        {is_submit_displayed &&
                                        <Grid item xs={12}>
                                            <Button variant={'contained'} classes={{root: classes.button}} type={'submit'}>
                                                CONTACT US
                                            </Button>
                                        </Grid>}
                                    </Grid>
                                </Paper>
                            </ValidatorForm>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        );
    }
;

export default ContactUs;