import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import './App.css';
import './styles.css';
import Home from './components/Home';
import Services from './components/Services';
import Solutions from './components/Solutions';
import Support from './components/Support';
import Values from './components/Values';
import Testimonials from './components/Testimonials';
import Blog from './components/Blog';
import MeetTheTeam from './components/MeetTheTeam';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';

const App = () => {
    return (
        <Router>
            <div id={'page-container'}>
                <Switch>
                    <Route exact path='/' component={Home}/>
                    <Route exact path='/home' component={Home}/>
                    <Route exact path='/services' component={Services}/>
                    <Route exact path='/solutions' component={Solutions}/>
                    <Route exact path='/support' component={Support}/>
                    <Route exact path='/values' component={Values}/>
                    <Route exact path='/testimonials' component={Testimonials}/>
                    <Route exact path='/blog' component={Blog}/>
                    <Route exact path='/meet-the-team' component={MeetTheTeam}/>
                    <Route exact path='/contactus' component={ContactUs}/>
                </Switch>
            </div>

            <Footer style={{height: '2.5rem'}}/>
        </Router>
    );
}

export default App;