import React, {useEffect} from 'react';
import {Box, Grid, makeStyles, Typography, Paper, useMediaQuery, useTheme} from '@material-ui/core';
import Navigation from './Navigation';
import logo from '../assets/img/icons/favicon_white.svg';
import '../styles.css';

const useStyles = makeStyles({
    typography: {
        fontFamily: 'var(--tabs-font-family)',
        fontSize: '1rem',
        '@media screen and (max-width: 1600px), (max-device-width: 1600px)': {
            fontSize: '0.95rem'
        },
        '@media screen and (max-device-width: 1200px) and (orientation: landscape)': {
            fontSize: '0.9rem'
        },
        '@media screen and (max-width: 960px), (max-device-width: 1200px)': {
            fontSize: '1.1rem'
        },
        '@media screen and (max-height: 900px), (max-device-width: 900px)': {
            fontSize: '0.8rem'
        },
        '@media screen and (max-device-width: 770px)': {
            fontSize: '1.1rem'
        },
        '@media screen and (max-device-width: 740px) and (orientation: landscape)': {
            fontSize: '0.9rem'
        },
        '@media screen and (max-width: 690px), (max-device-width: 690px)': {
            fontSize: '0.9rem'
        },
        '@media screen and (max-device-width: 600px) and (orientation: portrait)': {
            fontSize: '0.655rem'
        },
        '@media screen and (max-width: 550px), (max-device-width: 550px)': {
            fontSize: '0.8rem'
        },
        '@media screen and (max-device-width: 450px) and (orientation: portrait)': {
            fontSize: '0.7rem'
        },
        '@media screen and (max-device-width: 670px) and (orientation: landscape)': {
            fontSize: '0.7rem'
        },
        '@media screen and (max-device-width: 400px)': {
            fontSize: '0.66rem'
        },
        '@media screen and (max-device-width: 350px)': {
            fontSize: '0.7rem'
        }
    },
    header: {
        zIndex: 5555,
        color: 'var(--text)',
        fontFamily: 'var(--landing-font-family)',
        fontWeight: 100,
        '@media screen and (max-width: 1280px), (max-device-width: 1280px) and (orientation: landscape)': {
            fontSize: '2.5rem'
        },
        '@media screen and (max-width: 960px)': {
            fontSize: '2.3rem'
        },
        '@media screen and (max-width: 600px), (max-device-width: 770px)': {
            fontSize: '2rem'
        },
        '@media screen and (max-device-width: 1200px) and (orientation: landscape)': {
            fontSize: '2rem'
        },
        '@media screen and (max-device-width: 850px)': {
            fontSize: '1.6rem'
        },
        '@media screen and (max-device-width: 350px)': {
            fontSize: '1rem'
        }
    },
    headerPosition: {
        margin: '15px 0'
    },
    paper: {
        background: 'var(--card-background)',
        opacity: 0.95,
        color: 'var(--card-text)',
        padding: '10px',
        margin: '20px 0',
        border: 'none',
        '@media screen and (max-width: 1280px)': {
            margin: '20px'
        }
    },
    contentContainer: {
        padding: '0 50px',
        '@media screen and (max-width: 1280px)': {
            padding: '0 10px'
        }
    },
    lastLine: {
        marginBottom: '10vh'
    }
});

const Testimonials = () => {
    const classes = useStyles();
    const theme = useTheme();
    const is_over_medium = useMediaQuery(theme.breakpoints.up('lg'));

    const network_integrator = {
        text: 'We have worked with the staff at VigilantIoE for over 3-Years. The value they bring to us and our clients is immeasurable. The constant attention to detail and exemplary support has enabled us to grow our Monitoring and Maintenance business 600%. Their approach to the “Single Pane of Glass” methodology has transcended every supported manufacturer and OEM. Their support employees have quickly demonstrated their value, delivering a quality product that keeps our engineering staff up to speed on events in our DAS and Networking engagements. The measure of any company is their staff. VigilantIoE has continued to provide great service and value for our customers.',
        author: 'Mike, A National DAS/Network Integrator'
    };

    const technology_solutions = {
        text: 'This is a top notch team that consistently exceeds expectations.  We’ve communicated our pain points and they have provided a product that really works to make our life easier.  They are responsive and open to suggestions that are tailored to our unique situation.  In this world of mediocre delivery, we can attest that they are providing what we need and we look forward to a long and rewarding partnership.',
        author: 'Pete, Technology Solutions Provider'
    };

    const mixed_developers = {
        text: 'We worked with the VigilantIoE team to develop cutting-edge custom software at the interface of technology and the built environment. They have been excellent partners, quickly translating business needs into technology solutions, delivering high quality work products, and anticipating our needs every step of the way. The VigilantIoE team is the best of the best and I would recommend them to anyone.',
        author: 'Patrick, National and Mixed Use Developers'
    };

    const airvaiwz = {
        text: 'We have worked extensively with the Vigilant team members in defining and developing the platform to meet our monitoring needs and they have always delivered promptly with a great product. Their ability and willingness to customize to meet our desires has been refreshing and appealing throughout the process.',
        author: 'Joseph Perrigo, Executive Vice President, Construction & Operations, Airwavz Solutions'
    };

    const notora = {
        text: 'Vigilant IOE’s Virgil platform has been a remarkable addition to Notora’s toolkit for providing peace of mind to our clients. The GUI structure and incredible customization options were precisely what our clients needed to keep their networks operational with the maximum possible uptime. As a company comprised almost entirely of RF Engineers, we appreciate partners who are at the pinnacle of their technical game and the team at Vigilant certainly meet that standard.',
        author: 'Marc Knapp, Co-Founder / Vice President, Business Development, Notora'
    };

    const TestimonialCard = ({client}) => {
        const {text, author} = client;

        return (
            <Paper elevation={3} className={'card'} classes={{root: classes.paper}} component={'figure'}>
                <Typography classes={{root: classes.typography}} paragraph align={'justify'} component={'div'}>
                    <Typography classes={{root: classes.typography}} paragraph>
                        "{text}"
                    </Typography>

                    <Typography align={'right'} classes={{root: classes.typography}}>
                        <i>{author}</i>
                    </Typography>
                </Typography>
            </Paper>
        );
    };

    useEffect(() => {
        if (is_over_medium) {
            const radius = 550;
            const auto_rotate = true;
            const rotate_speed = -200;
            const card_width = window.innerWidth > 2000 ? 20 : 35;
            const card_height = 40;

            let sX, sY, nX, nY, desX = 0,
                desY = 0,
                tX = 0,
                tY = 10;

            const obj_drag = window.document.getElementById('drag');
            const obj_spin = window.document.getElementById('spin');
            const cards = obj_spin.getElementsByTagName('figure');
            const spinning_elements = [...cards];

            obj_spin.style.width = `${card_width}vw`;
            obj_spin.style.height = `${card_height}vh`;

            const obj_ground = window.document.getElementById('ground');

            obj_ground.style.width = '100vw';
            obj_ground.style.height = '100vh';

            const init = (delay) => {
                for (let i = 0; i < spinning_elements.length; i++) {
                    spinning_elements[i].style.transform = 'rotateY(' + (i * (360 / spinning_elements.length)) + 'deg) translateZ(' + radius + 'px)';
                    spinning_elements[i].style.transition = 'transform 5s';
                    spinning_elements[i].style.transitionDelay = delay || (spinning_elements.length - i) / 4 + 's';
                }
            };

            const applyTransform = (obj) => {
                if (tY > 180) tY = 180;
                if (tY < 0) tY = 0;

                obj.style.transform = 'rotateX(' + (-tY) + 'deg) rotateY(' + (tX) + 'deg)';
            };

            const playSpin = (yes) => {
                obj_spin.style.animationPlayState = (yes ? 'running' : 'paused');
            }

            if (auto_rotate) {
                const animation_name = (rotate_speed > 0 ? 'spin' : 'spinRevert');
                obj_spin.style.animation = `${animation_name} ${Math.abs(rotate_speed)}s infinite linear`;
            }

            window.document.onpointerdown = function (e) {
                clearInterval(obj_drag.timer);
                e = e || Event;
                sX = e.clientX;
                sY = e.clientY;

                playSpin(false);

                this.onpointermove = function (e) {
                    e = e || Event;
                    nX = e.clientX;
                    nY = e.clientY;
                    desX = nX - sX;
                    desY = nY - sY;
                    tX += desX * 0.1;
                    tY += desY * 0.1;

                    applyTransform(obj_drag);

                    sX = nX;
                    sY = nY;
                };

                this.onpointerup = function (e) {
                    obj_drag.timer = setInterval(() => {
                        desX *= 0.95;
                        desY *= 0.95;
                        tX += desX * 0.1;
                        tY += desY * 0.1;

                        applyTransform(obj_drag);
                        playSpin(false);

                        if (Math.abs(desX) < 0.5 && Math.abs(desY) < 0.5) {
                            clearInterval(obj_drag.timer);
                            playSpin(true);
                        }
                    }, 17);

                    this.onpointermove = this.onpointerup = null;
                };

                return false;
            };

            setTimeout(init, 1000);
        }
    }, [is_over_medium]);

    const CircleCarousel = () => {
        return (
            <Box id={'drag'}>
                <Box id={'spin'}>
                    <TestimonialCard client={network_integrator}/>
                    <TestimonialCard client={airvaiwz}/>
                    <TestimonialCard client={notora}/>
                    <TestimonialCard client={technology_solutions}/>
                    <TestimonialCard client={mixed_developers}/>

                    <img src={logo} alt={''} style={{height: '38vh'}}/>
                </Box>

                <Box id={'ground'}/>
            </Box>
        );
    };

    return (
        <>
            <Navigation/>

            <Grid container className={'grid-container'} classes={{root: classes.contentContainer}}>
                <Grid container item xs={12} className={'header-service'} classes={{item: classes.headerPosition}}>
                    <Typography variant={'h3'} align={'center'} component={'header'} classes={{root: classes.header}}>
                        THANK YOU! WE THINK YOU'RE GREAT TOO
                    </Typography>
                </Grid>

                {is_over_medium ?
                    <Grid container>
                        <Grid item xs={12}>
                            <Box style={{margin: '15vh auto 0 auto'}}>
                                <CircleCarousel/>
                            </Box>
                        </Grid>
                    </Grid>
                    :
                    <>
                        <Grid container item xs={12} justifyContent={'space-between'}>
                            <Grid item xs={12} md={6} lg={5}>
                                <TestimonialCard client={network_integrator}/>
                            </Grid>

                            <Grid item xs={12} md={6} lg={5}>
                                <TestimonialCard client={notora}/>
                            </Grid>
                        </Grid>

                        {is_over_medium ?
                            <Grid container item xs={12} justifyContent={'space-between'}>
                                <Grid item xs={12} md={6} lg={4}>
                                    <TestimonialCard client={mixed_developers}/>
                                </Grid>

                                <Grid item xs={12} md={6} lg={3}>
                                    <TestimonialCard client={airvaiwz}/>
                                </Grid>

                                <Grid item xs={12} md={6} lg={4} classes={{root: classes.lastLine}}>
                                    <TestimonialCard client={technology_solutions}/>
                                </Grid>
                            </Grid>
                            :
                            <>
                                <Grid container item xs={12} justifyContent={'space-between'}>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <TestimonialCard client={mixed_developers}/>
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={4}>
                                        <TestimonialCard client={technology_solutions}/>
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} justifyContent={'center'} classes={{root: classes.lastLine}}>
                                    <Grid item xs={12} md={6} lg={4}>
                                        <TestimonialCard client={airvaiwz}/>
                                    </Grid>
                                </Grid>
                            </>
                        }
                    </>
                }
            </Grid>
        </>
    );
};

export default Testimonials;