import React from 'react';
import ComingSoon from './ComingSoon';
import Navigation from './Navigation';

const Blog = () => {
    return (
        <>
            <Navigation/>
            <ComingSoon/>
        </>
    );
};

export default Blog;

/*
import React, {useEffect, useState} from 'react';
import {CssBaseline, Container, Grid, Toolbar, Link, Typography, Divider, Paper} from '@material-ui/core';
import {makeStyles} from '@material-ui/core';
import Markdown from './Markdown';

const useStyles = makeStyles((theme) => ({
    mainGrid: {
        marginTop: theme.spacing(3),
        justifyContent: 'center'
    },
    listItem: {
        marginTop: theme.spacing(1)
    },
    markdown: {
        ...theme.typography.body2,
        padding: theme.spacing(3, 0)
    },
    header: {
        justifyContent: 'space-evenly',
        overflowX: 'auto'
    },
    link: {
        padding: theme.spacing(1),
        flexShrink: 0
    },
    post: {
        padding: '25px',
        margin: '10px 0'
    },
    postTitle: {
        margin: 0
    },
    postCreds: {
        margin: '5px 0',
        fontWeight: 'normal'
    }
}));

const Blog = () => {

    const classes = useStyles();

    const [pages, setPages] = useState();

    const sections = [{title: 'News', url: '#'}, {title: 'Team Blog', url: '$'}, {title: 'About Us', url: '#'}];

    useEffect(() => {
        const getData = async () => {

            const data = await fetch('https://ggxn5oiuv8.execute-api.us-east-2.amazonaws.com/v1/getblogData', {
                headers: {
                    'x-api-key': process.env.REACT_APP_API_KEY,
                    'Accept': 'application/json'
                }
            })
                .then(res => res.json())
                .then(json => {
                    console.log('DATABLOG', json);
                    return json.body;
                })
                .catch(err => console.log('getData', err));

            const pages = Object.keys(data).map(key => data[key]);
            setPages(pages);
        };
        getData();
    }, []);

    return (
        <>
            <CssBaseline/>
            <Container maxWidth='lg'>
                <Toolbar component='nav' variant='dense' className={classes.header}>
                    {sections.map((section) => (
                        <Link
                            color='inherit'
                            noWrap
                            key={section.title}
                            variant='body2'
                            href={section.url}
                            className={classes.link}
                        >
                            {section.title}
                        </Link>
                    ))}
                </Toolbar>
                <main>
                    {/!*<MainFeaturedPost post={mainFeaturedPost}/>
                    <Grid container spacing={4}>
                        {featuredPosts.map((post) => (
                            <FeaturedPost key={post.title} post={post}/>
                        ))}
                    </Grid>*!/}
                    <Grid container spacing={5} className={classes.mainGrid}>
                        {/!*<MainScroll title="From the firehose" posts={posts}/>*!/}
                        <Grid item xs={12} md={10}>
                            <Typography variant='h6' gutterBottom>
                                Posts
                            </Typography>
                            <Divider/>
                            {pages && pages.map(page => (
                                <Paper elevation={3} className={classes.post}>
                                    <h2 className={classes.postTitle}>{page.title}</h2>
                                    <h6 className={classes.postCreds}>
                                        <span>Created by {page.history.createdBy.displayName}</span>
                                        <span>at {page.history.createdDate}</span></h6>
                                    <h6 className={classes.postCreds}>
                                        <span>Last Updated by {page.history.lastUpdated.by.displayName}</span>
                                        <span>at {page.history.lastUpdated.when}</span></h6>
                                    <Markdown className={classes.markdown} key={page.title}>
                                        {page.body.view.value}
                                    </Markdown>
                                </Paper>
                            ))}
                        </Grid>
                        {/!*<Sidebar
                            title={sidebar.title}
                            description={sidebar.description}
                            archives={sidebar.archives}
                            social={sidebar.social}
                        />*!/}
                    </Grid>
                </main>
            </Container>
            {/!*<Footer title="Footer" description="Something here to give the footer a purpose!"/>*!/}
            {/!*{pages &&
            pages.map(page => (
                <>
                    <h3>{page.title}</h3>
                    <div key={page.title} dangerouslySetInnerHTML={{__html: page.body.view.value}}/>
                </>
            ))
            }*!/}
        </>
    )
};

export default Blog;*/
