import React, {useState} from 'react';
import ReactCardFlip from 'react-card-flip';
import '../styles.css';
import {Box, Card, CardContent, Grid, Link, makeStyles, Typography} from '@material-ui/core';
import Ticker from 'react-ticker';
import Navigation from './Navigation';
import normalizeCaps from '../services/normalizeCaps';
import favicon from '../assets/img/icons/favicon_dark_green.svg';
import virgil_logo from '../assets/img/logo/Virgil_logo_dark_background.svg';
import consult_card from '../assets/img/services/consult_card.svg';
import monitor_card from '../assets/img/services/monitor_card.svg';
import networkioe_card from '../assets/img/services/networkioe_card.svg';

const useStyles = makeStyles({
    header: {
        color: 'var(--text)',
        fontFamily: 'var(--landing-font-family)',
        fontWeight: 100,
        '@media screen and (max-width: 1280px), (max-device-width: 1280px) and (orientation: landscape)': {
            fontSize: '2.5rem'
        },
        '@media screen and (max-width: 960px)': {
            fontSize: '2.3rem'
        },
        '@media screen and (max-width: 600px), (max-device-width: 770px)': {
            fontSize: '2rem'
        },
        '@media screen and (max-device-width: 1200px) and (orientation: landscape)': {
            fontSize: '2rem'
        },
        '@media screen and (max-device-width: 850px)': {
            fontSize: '1.5rem'
        },
        '@media screen and (max-device-width: 350px)': {
            fontSize: '1rem'
        }
    },
    headerPosition: {
        margin: '30px 0'
    },
    cardFrontText: {
        fontFamily: 'var(--landing-font-family)',
        fontWeight: 500,
        fontSize: '3rem',
        color: 'var(--card-text)',
        '@media screen and (max-width: 1650px)': {
            fontSize: '2rem'
        },
        '@media screen and (max-device-width: 1200px)': {
            fontSize: '1.7rem'
        },
        '@media screen and (max-width: 850px)': {
            fontSize: '1.4rem'
        },
        '@media screen and (max-width: 600px)': {
            fontSize: '1.2rem'
        },
        '@media screen and (max-width: 500px)': {
            fontSize: '0.8rem'
        },
        '@media screen and (max-width: 300px)': {
            fontSize: '0.6rem'
        }
    },
    cardBackText: {
        fontFamily: 'var(--landing-font-family)',
        fontSize: '1.3rem',
        color: 'var(--card-text)',
        lineHeight: 2,
        '@media screen and (max-width: 1650px) and (min-width: 1451px), (max-width: 960px)': {
            fontSize: '1.1rem'
        },
        '@media screen and (max-width: 1450px) and (min-width: 1281px), (max-width: 800px)': {
            fontSize: '0.9rem'
        },
        '@media screen and (max-width: 680px)': {
            fontSize: '0.7rem'
        },
        '@media screen and (max-width: 550px)': {
            fontSize: '0.5rem'
        },
        '@media screen and (max-width: 300px)': {
            fontSize: '0.4rem'
        }
    },
    paragraph: {
      '@media screen and (max-width: 600px)': {
          marginBottom: '6px'
      },
        '@media screen and (max-width: 300px)': {
            marginBottom: '3px'
        }
    },
    ticker: {
        margin: '20px 0 10vh 0'
    }
});

const Services = () => {

    const classes = useStyles();

    const renderMonitorCardBackText = () => {
        return (
            <>
                <Typography classes={{root: classes.cardBackText, paragraph: classes.paragraph}} paragraph>
                    Centralized ~ Unified ~ Insight
                </Typography>

                <Typography classes={{root: classes.cardBackText, paragraph: classes.paragraph}} paragraph>
                    Gathering, aggregating and analyzing data. Recognizing anomalies, trends and degradation.
                </Typography>

                <Typography classes={{root: classes.cardBackText, paragraph: classes.paragraph}} paragraph>
                    Simplify
                    &nbsp;<img src={favicon} alt={'eye'} style={{width: '2vh'}}/>&nbsp;
                    Automate
                    &nbsp;<img src={favicon} alt={'eye'} style={{width: '2vh'}}/>&nbsp;
                    Predict
                </Typography>

                <Typography classes={{root: classes.cardBackText, paragraph: classes.paragraph}} paragraph>
                    <i>Meet...</i>
                </Typography>

                <Link href={'/solutions'}>
                    <img src={virgil_logo} alt={'Virgil_logo'} style={{width: '50%'}}/>
                </Link>
            </>
        );
    };

    const renderNetworkIoECardBackText = () => {
        return (
            <>
                <Typography classes={{root: classes.cardBackText, paragraph: classes.paragraph}} paragraph>
                    Evaluate ~ Design ~ Support
                </Typography>

                <Typography classes={{root: classes.cardBackText, paragraph: classes.paragraph}} paragraph>
                    Our expertise can assist in all aspects of your networking needs.
                </Typography>

                <Typography classes={{root: classes.cardBackText, paragraph: classes.paragraph}} paragraph>
                    Drive business efficiencies by unifying data, applications, physical components, processes and
                    tools.
                </Typography>
            </>
        );
    };

    const renderConsultCardBackText = () => {
        return (
            <>
                <Typography classes={{root: classes.cardBackText, paragraph: classes.paragraph}} paragraph>
                    Customized, expert evaluation, planning and support for your IT Systems.
                </Typography>

                <Typography classes={{root: classes.cardBackText, paragraph: classes.paragraph}} paragraph>
                    Integrating disparate systems and data for holistic network management.
                </Typography>

                <Typography classes={{root: classes.cardBackText, paragraph: classes.paragraph}} paragraph>
                    Stay ahead of the curve.
                </Typography>
            </>
        );
    };

    const services = [
        {
            frontText: 'MONITOR',
            backText: renderMonitorCardBackText(),
            background: monitor_card
        },
        {
            frontText: 'NETWORK IoE',
            backText: renderNetworkIoECardBackText(),
            background: networkioe_card
        },
        {
            frontText: 'CONSULT',
            backText: renderConsultCardBackText(),
            background: consult_card

        }
    ];

    const ticker_quotes = [
        {
            text: 'The constant attention to detail and exemplary support has enabled us to grow our Monitoring and Maintenance business 600%...',
            author: 'Mike, A National DAS/ Network Integrator'
        },
        {
            text: 'This is a top notch team that consistently exceeds expectations. We’ve communicated our pain points and they have provided a product that really works to make our life easier...',
            author: 'Pete, Technology Solutions Provider'
        },
        {
            text: 'They have been excellent partners, quickly translating business needs into technology solutions, delivering high quality work products, and anticipating our needs every step of the way...',
            author: 'Patrick, National and Mixed Use Developers'
        },
        {
            text: 'Their ability and willingness to customize to meet our desires has been refreshing and appealing...',
            author: 'Joseph, VP, Airwavz Solutions'
        },
        {
            text: 'The GUI structure and incredible customization options were precisely what our clients needed...',
            author: 'Marc, Co-Founder, VP, Notora'
        }
    ]

    const [is_card_flipped, setIsCardFlipped] = useState({
        monitor: false,
        networkioe: false,
        consult: false
    });

    const handleHover = (e) => {
        setIsCardFlipped({[normalizeCaps(e.currentTarget.textContent)]: true});
    };
    const handleMouseLeave = (e) => {
        setIsCardFlipped({
            monitor: false,
            networkioe: false,
            consult: false
        });
    };

    return (
        <>
            <Navigation/>

            <Grid container className={'grid-container'}>
                <Grid container item xs={12} className={'header-service'} classes={{item: classes.headerPosition}}>
                    <Typography variant={'h3'} align={'center'} component={'header'} classes={{root: classes.header}}>
                        HOW CAN WE HELP
                    </Typography>
                </Grid>

                {services.map(({frontText, backText, background}, i) => (
                    <Grid item xs={10} sm={8} lg={3} key={frontText}>
                        <ReactCardFlip isFlipped={is_card_flipped[normalizeCaps(frontText)]}
                                       flipDirection={'vertical'}
                                       containerStyle={(i !== 0 || i !== services.length - 1) ? {marginBottom: '15px'} : {marginBottom: '0'}}>

                            <Card onMouseOver={handleHover} className={'services-card'}
                                  style={{
                                      backgroundColor: 'var(--card-background)',
                                      color: 'var(--card-text)',
                                      backgroundImage: `url(${background})`,
                                      backgroundSize: 'contain',
                                      backgroundRepeat: 'no-repeat',
                                      backgroundPosition: 'center'
                                  }}>
                                <Box style={{
                                    width: '100%',
                                    position: 'absolute',
                                    bottom: '0px'
                                }}>
                                    <CardContent>
                                        <Typography variant={'h4'} classes={{root: classes.cardFrontText}} component={'div'}>
                                            {frontText}
                                        </Typography>
                                    </CardContent>
                                </Box>
                            </Card>

                            <Card onMouseLeave={handleMouseLeave} className={'services-card'}
                                  style={{
                                      background: 'var(--card-background)',
                                      opacity: 0.8,
                                      color: 'var(--card-text)'
                                  }}>
                                <CardContent style={{padding: '10px 0'}}>
                                    <Typography classes={{root: classes.cardBackText}} component={'div'}>
                                        {backText}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </ReactCardFlip>
                    </Grid>
                ))}

                <Grid container item xs={12} classes={{root: classes.ticker}}>
                    <Ticker speed={5} direction={'toLeft'} move={true} offset='run-in'>
                        {() => (
                            <>
                                <div style={{whiteSpace: 'nowrap'}}>
                                    <Link href={'/testimonials'} style={{
                                        color: 'var(--text)',
                                        textDecoration: 'none'
                                    }}>
                                        {ticker_quotes.map(({text, author}) => (
                                            <span className={'ticker'}><i>"{text}"</i>&nbsp;&nbsp;&nbsp;
                                                <b>{author}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        ))}
                                    </Link>
                                </div>
                            </>
                        )}
                    </Ticker>
                </Grid>
            </Grid>
        </>
    );
};

export default Services;