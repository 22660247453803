import React from 'react';
import logo from '../assets/img/logo/vigilantioe_logo.svg';

const LogoHome = () => {
    return (
        <>
            <a href={'/'}>
                <img src={logo} alt={'logo'} className={'logo-home'}/>
            </a>
        </>
    );
};

export default LogoHome;