import React, {useState} from 'react';
import {Link, makeStyles, Menu, MenuItem} from '@material-ui/core';

const useStyle = makeStyles({
    menuPaper: {
        backgroundColor: 'var(--menu-background)',
        color: 'var(--text)',
        fontFamily: 'var(--tabs-font-family)'
    }
});

const MenuDropDown = ({type}) => {
    const classes = useStyle();

    const dropdown_items = [
        {
            text: 'VALUES',
            link: 'values'
        },
        {
            text: 'TESTIMONIALS',
            link: 'testimonials'
        },
        {
            text: 'BLOG',
            link: 'blog'
        },
        {
            text: 'MEET THE TEAM',
            link: 'meet-the-team'
        }
    ];

    const [anchor_drop, setAnchorDrop] = useState(null);

    const handleOpenDropdown = (e) => {
        setAnchorDrop(e.currentTarget);
    };

    const handleCloseDropdown = () => {
        setAnchorDrop(null);
    };

    return (
        <>
            <Link style={{
                color: 'var(--text)',
                textDecoration: 'none',
                margin: type === 'wide' ? '0 50px' : '0',
                fontFamily: 'var(--tabs-font-family)',
                cursor: 'pointer'
            }}
                  onClick={handleOpenDropdown}
                  data-link={Boolean(dropdown_items.find(({link}) => window.location.pathname.substr(1) === link))}>
                ABOUT US
            </Link>

            <Menu id={'menu-dropdown'}
                  anchorEl={anchor_drop}
                  keepMounted
                  open={Boolean(anchor_drop)}
                  onClose={handleCloseDropdown}
                  getContentAnchorEl={null}
                  anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center'
                  }}
                  transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                  }}
                  classes={{paper: classes.menuPaper}}
                  style={{opacity: 0.9, zIndex: 9999}}
            >
                {dropdown_items.map(({text, link}, i) => (
                    <MenuItem key={`dropdown_${i}`} onClick={handleCloseDropdown}>
                        <Link href={`/${link}`}
                              style={{
                                  color: 'var(--text)',
                                  textDecoration: 'none',
                                  fontFamily: 'var(--tabs-font-family)',
                                  fontSize: '0.8rem'
                              }}
                              data-link={window.location.pathname.substr(1) === link}>
                            {text}
                        </Link>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default MenuDropDown;