import React from 'react';
import ReactDOM from 'react-dom';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import './index.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './styles.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-200663285-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const theme = createTheme({
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '*, *::before, *::after': {
                    padding: '0',
                    margin: '0',
                    boxSizing: 'border-box',
                },
                html: {
                    scrollBehavior: 'smooth'
                },
                body: {
                    fontFamily: 'var(--landing-font-family)',
                    color: 'var(--text)',
                    backgroundColor: 'var(--background-color)',
                    background: 'var(--background)',
                    height: '100vh',
                    position: 'relative',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundAttachment: 'fixed',
                    backgroundSize: 'cover'
                }
            }
        }
    }
});
export default theme;

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <React.StrictMode>
            <App/>
        </React.StrictMode>
    </MuiThemeProvider>,
    document.getElementById('root')
);

reportWebVitals();