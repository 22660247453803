import React from 'react';
import {Box, Link, makeStyles, Typography} from '@material-ui/core';
import {LinkedIn, MailOutlineRounded, PhoneRounded} from '@material-ui/icons';

const useStyles = makeStyles({
    text: {
        display: 'inline-flex',
        padding: '0 10px',
        fontFamily: 'var(--tabs-font-family)',
        fontWeight: 100,
        fontSize: '1.1rem',
        '@media screen and (max-width: 1280px), (max-device-width: 1280px)': {
            fontSize: '1rem'
        },
        '@media screen and (max-width: 700px), (max-device-width: 700px)': {
            fontSize: '0.8rem'
        },
        '@media screen and (max-device-width: 400px)': {
            fontSize: '0.65rem'
        }
    },
    link: {
        color: 'var(--text)'
    },
    icon: {
        fontSize: '1.3rem',
        '@media screen and (max-width: 1280px), (max-device-width: 1280px)': {
            fontSize: '1.2rem'
        },
        '@media screen and (max-device-width: 400px)': {
            fontSize: '0.9rem'
        }
    },
    paragraph: {
        marginBottom: '4px'
    }
});

const Footer = () => {
    const classes = useStyles();

    return (
        <Box className={'footer'} component={'footer'}>
            <Typography align={'center'} component={'div'}
                        classes={{root: classes.text, paragraph: classes.paragraph}}
                        paragraph className={'footer-info'}>
                <PhoneRounded classes={{root: classes.icon}}/>
                &nbsp;
                <p>+1-888-620-0119</p>
            </Typography>

            <Typography align={'center'} component={'div'}
                        classes={{root: classes.text, paragraph: classes.paragraph}}
                        paragraph className={'footer-info'}>
                <MailOutlineRounded classes={{root: classes.icon}}/>
                &nbsp;
                <p>support@vigilantioe.com</p>
            </Typography>

            <Typography align={'center'} component={'div'}
                        classes={{root: classes.text, paragraph: classes.paragraph}}
                        paragraph className={'footer-info'}>
                <MailOutlineRounded classes={{root: classes.icon}}/>
                &nbsp;
                <p>sales@vigilantioe.com</p>
            </Typography>

            <Typography align={'center'} component={'div'}
                        className={'footer-info'}>
                <Link href={'https://www.linkedin.com/company/vigilantioe/'}
                      TypographyClasses={{colorPrimary: classes.link}}>
                    <LinkedIn classes={{root: classes.icon}}/>
                </Link>
            </Typography>
        </Box>
    );
};

export default Footer;