import React from 'react';
import Particles from 'react-tsparticles';
import cloud from '../assets/img/icons/cloud_upload_white_24dp.svg';
import stream from '../assets/img/icons/stream_white_24dp.svg';
import sensors from '../assets/img/icons/sensors_white_24dp.svg';
import public_ from '../assets/img/icons/public_white_24dp.svg';
import antenna from '../assets/img/icons/settings_input_antenna_white_24dp.svg';
import favicon from '../assets/img/icons/favicon_white.svg';
import account from '../assets/img/icons/account_balance_white_24dp.svg';
import business from '../assets/img/icons/business_white_24dp.svg';
import bus from '../assets/img/icons/directions_bus_white_24dp.svg';
import flight from '../assets/img/icons/flight_white_24dp.svg';
import bulb from '../assets/img/icons/lightbulb_white_24dp.svg';
import van from '../assets/img/icons/local_shipping_white_24dp.svg';
import taxi from '../assets/img/icons/local_taxi_white_24dp.svg';
import location from '../assets/img/icons/location_on_white_24dp.svg';
import maps from '../assets/img/icons/maps_home_work_white_24dp.svg';
import medicine from '../assets/img/icons/medical_services_white_24dp.svg';
import phone from '../assets/img/icons/phone_iphone_white_24dp.svg';
import savings from '../assets/img/icons/savings_white_24dp.svg';
import school from '../assets/img/icons/school_white_24dp.svg';
import science from '../assets/img/icons/science_white_24dp.svg';
import shopping from '../assets/img/icons/shopping_cart_white_24dp.svg';
import triangle from '../assets/img/icons/square_foot_white_24dp.svg';
import train from '../assets/img/icons/train_white_24dp.svg';
import video from '../assets/img/icons/videocam_white_24dp.svg';
import water from '../assets/img/icons/water_drop_white_24dp.svg';
import '../styles.css';

const Background = () => {
    return (
        <div className={'moving-background'}>
            <Particles
                height={'99vh'}
                style={{position: 'fixed'}}
                options={{
                    particles: {
                        number: {
                            value: 30,
                            density: {
                                enable: true,
                                value_area: 800
                            }
                        },
                        links: {
                            color: "#ccc",
                            distance: 150,
                            enable: true,
                            opacity: 0.7,
                            width: 1,
                        },
                        move: {
                            speed: 1,
                            direction: "left",
                            enable: true,
                            outMode: "out"
                        },
                        shape: {
                            type: [
                                "image"
                            ],
                            image: [
                                {
                                    src: cloud,
                                    height: 20,
                                    width: 23
                                },
                                {
                                    src: stream,
                                    height: 20,
                                    width: 20
                                },
                                {
                                    src: public_,
                                    height: 20,
                                    width: 20
                                },
                                {
                                    src: sensors,
                                    height: 20,
                                    width: 20
                                },
                                {
                                    src: antenna,
                                    height: 20,
                                    width: 20
                                },
                                {
                                    src: favicon,
                                    height: 20,
                                    width: 20
                                },
                                {
                                    src: account,
                                    height: 20,
                                    width: 20
                                },
                                {
                                    src: business,
                                    height: 20,
                                    width: 20
                                },
                                {
                                    src: bus,
                                    height: 20,
                                    width: 20
                                },
                                {
                                    src: flight,
                                    height: 20,
                                    width: 20
                                },
                                {
                                    src: bulb,
                                    height: 20,
                                    width: 20
                                },
                                {
                                    src: van,
                                    height: 20,
                                    width: 20
                                },
                                {
                                    src: taxi,
                                    height: 20,
                                    width: 20
                                },
                                {
                                    src: location,
                                    height: 20,
                                    width: 20
                                },
                                {
                                    src: maps,
                                    height: 20,
                                    width: 20
                                },
                                {
                                    src: medicine,
                                    height: 20,
                                    width: 20
                                },
                                {
                                    src: phone,
                                    height: 20,
                                    width: 20
                                },
                                {
                                    src: savings,
                                    height: 20,
                                    width: 20
                                },
                                {
                                    src: science,
                                    height: 20,
                                    width: 20
                                },
                                {
                                    src: school,
                                    height: 20,
                                    width: 20
                                },
                                {
                                    src: shopping,
                                    height: 20,
                                    width: 20
                                },
                                {
                                    src: triangle,
                                    height: 20,
                                    width: 20
                                },
                                {
                                    src: train,
                                    height: 20,
                                    width: 20
                                },
                                {
                                    src: video,
                                    height: 20,
                                    width: 20
                                },
                                {
                                    src: water,
                                    height: 20,
                                    width: 20
                                }
                            ]
                        },
                        color: {
                            value: "#ccc"
                        },
                        size: {
                            value: 30,
                            random: false,
                            anim: {
                                enable: true,
                                speed: 4,
                                size_min: 10,
                                sync: false
                            }
                        }
                    },
                    interactivity: {
                        events: {
                            onHover: {
                                enable: true,
                                mode: "bubble"
                            }
                        },
                        modes: {
                            bubble: {
                                distance: 250,
                                duration: 2,
                                size: 0,
                                opacity: 0
                            },
                            repulse: {
                                distance: 400,
                                duration: 4
                            }
                        }
                    },
                    detectRetina: false
                }}
            />
        </div>
    );
};

export default Background;