import React, {useState} from 'react';
import ReactCardFlip from 'react-card-flip';
import {Box, Card, CardContent, Grid, makeStyles, Typography} from '@material-ui/core';
import Navigation from './Navigation';
import virgil_logo from '../assets/img/logo/Virgil_logo_dark_background.svg';
import chip from '../assets/img/icons/chip.svg';
import industries_card from '../assets/img/solutions/industries_card.svg';
import supportedioe_card from '../assets/img/solutions/supportedioe_card.svg';
import normalizeCaps from '../services/normalizeCaps';
import '../styles.css';

const useStyles = makeStyles({
    cardFrontText: {
        fontFamily: 'var(--landing-font-family)',
        fontWeight: 500,
        fontSize: '2rem',
        color: 'var(--card-text)',
        letterSpacing: '10px',
        '@media screen and (max-width: 1650px) and (min-width: 960px)': {
            fontSize: '1.7rem'
        },
        '@media screen and (max-width: 1450px) and (min-width: 960px), (max-width: 960px)': {
            fontSize: '1.5rem'
        },
        '@media screen and (max-width: 1350px) and (min-width: 960px)': {
            fontSize: '1.3rem'
        },
        '@media screen and (max-width: 1200px) and (min-width: 960px), (max-width: 670px)': {
            fontSize: '1rem'
        },
        '@media screen and (max-width: 1100px) and (min-width: 960px)': {
            fontSize: '0.8rem'
        },
        '@media screen and (max-device-width: 1100px) and (min-device-width: 1000px)': {
            fontSize: '1.5rem'
        },
        '@media screen and (max-device-width: 550px)': {
            fontSize: '0.9rem'
        },
        '@media screen and (max-width: 450px), (max-device-width: 450px)': {
            fontSize: '0.6rem'
        },
        '@media screen and (max-width: 350px), (max-device-width: 350px)': {
            fontSize: '0.4rem'
        },
        '@media screen and (max-width: 300px), (max-device-width: 300px)': {
            letterSpacing: '7px'
        }
    },
    typographyFont: {
        fontFamily: 'var(--landing-font-family)',
        fontWeight: 300,
        fontSize: '0.96rem',
        '@media screen and (max-width: 960px), (max-device-width: 960px)': {
            fontSize: '0.7rem'
        },
        '@media screen and (max-device-width: 1500px) and (orientation: landscape)': {
            fontSize: '1rem'
        },
        '@media screen and (max-device-width: 850px) and (orientation: landscape)': {
            fontSize: '0.7rem'
        },
        '@media screen and (max-width: 420px), (max-device-width: 420px)': {
            fontSize: '0.6rem'
        },
        '@media screen and (max-width: 300px), (max-device-width: 300px)': {
            fontSize: '0.5rem'
        }
    },
    bottomTypography: {
        fontFamily: 'var(--landing-font-family)',
        fontWeight: 300,
        fontSize: '0.96rem',
        '@media screen and (max-width: 1280px), (max-device-width: 1280px)': {
            fontSize: '0.8rem'
        },
        '@media screen and (max-width: 960px), (max-device-width: 960px)': {
            fontSize: '0.7rem'
        },
        '@media screen and (max-width: 420px), (max-device-width: 420px)': {
            fontSize: '0.6rem'
        },
        '@media screen and (max-width: 300px), (max-device-width: 300px)': {
            fontSize: '0.5rem'
        }
    },
    cardContent: {
        padding: '0 10px'
    },
    body1: {
        marginBottom: '5px',
        fontSize: '0.96rem',
        fontFamily: 'var(--tabs-font-family)',
        '@media screen and (max-width: 1600px), (max-device-width: 1600px)': {
            fontSize: '0.8rem'
        },
        '@media screen and (max-width: 1400px)': {
            fontSize: '0.7rem'
        },
        '@media screen and (max-width: 1200px)': {
            fontSize: '0.6rem'
        },
        '@media screen and (max-width: 1100px)': {
            fontSize: '0.5rem'
        },
        '@media screen and (max-width: 960px)': {
            fontSize: '0.7rem'
        },
        '@media screen and (max-width: 660px), (max-device-width: 670px)': {
            fontSize: '0.6rem'
        },
        '@media screen and (max-width: 520px), (max-device-width: 520px)': {
            fontSize: '0.5rem'
        },
        '@media screen and (max-width: 450px), (max-device-width: 450px)': {
            fontSize: '0.4rem',
            marginBottom: '4px'
        },
        '@media screen and (max-width: 350px), (max-device-width: 350px)': {
            fontSize: '0.3rem',
            marginBottom: '2px'
        }
        ,
        '@media screen and (max-device-width: 1100px) and (min-device-width: 1000px)': {
            fontSize: '1rem'
        }
    },
    paragraph: {
        marginBottom: '6px'
    },
    virgil: {
        marginBottom: '20px',
        '@media screen and (max-device-width: 1100px)': {
            maxWidth: '100%',
            flexBasis: '100%'
        }
    },
    cards: {
        alignItems: 'center',
        marginBottom: '20px',
        '@media screen and (max-device-width: 1100px) and (min-device-width: 1000px)': {
            maxWidth: '50%',
            flexBasis: '50%'
        }
    },
    industryCardFront: {
        display: 'flex',
        '@media screen and (max-device-width: 1100px) and (min-device-width: 1000px)': {
            maxWidth: '0%',
            flexBasis: '0%'
        },
        '@media screen and (max-device-width: 400px)': {
            maxWidth: '30%',
            flexBasis: '30%'
        }
    },
    lastLine: {
        '&:last-child': {
            marginBottom: '2rem',
            '@media screen and (max-width: 960px)': {
                marginBottom: '8rem'
            },
            '@media screen and (max-device-width: 1150px)': {
                marginBottom: '10vh'
            }
        }
    }
});

const Solutions = () => {
    const classes = useStyles();

    const renderIndustriesFront = () => {
        return (
            <>
                <Grid container item alignItems={'center'} justifyContent={'center'}>
                    <Grid container item xs={1} justifyContent={'flex-end'} classes={{root: classes.industryCardFront}}>
                        <Typography className={'rotated-text'} classes={{root: classes.cardFrontText}} variant={'h6'}
                                    align={'center'}>
                            INDUSTRIES
                        </Typography>
                    </Grid>
                </Grid>
            </>
        );
    };

    const renderSupportedIoeFront = () => {
        return (
            <>
                <Grid container item alignItems={'center'} justifyContent={'center'}>
                    <Grid container item xs={1} sm={2} style={{display: 'flex', marginRight: '15px'}}
                          justifyContent={'center'}>
                        <Typography className={'rotated-text'} classes={{root: classes.cardFrontText}} variant={'h6'}
                                    style={{whiteSpace: 'nowrap'}} align={'center'}>
                            SUPPORTED IOE
                        </Typography>
                    </Grid>

                    <Grid item xs={7} lg={8}>
                        <img src={chip} alt={'Chip icon'} className={'supportedioe-icons'}/>
                    </Grid>
                </Grid>
            </>
        );
    };

    const renderBackCardText = (card_name) => {
        return (
            card_name.map(el => (
                <Typography key={el} variant={'body1'} align={'center'}
                            classes={{body1: classes.body1}} paragraph>
                    {el}
                </Typography>))
        );
    };

    const industries = ['AGRICULTURE', 'EDUCATION', 'ENERGY', 'ENTERPRISE', 'FINANCIAL', 'GOVERNMENT', 'HEALTHCARE / PHARMA', 'OIL & GAS', 'MANUFACTURING', 'LEGAL', 'RETAIL / MIXED USE', 'TECHNOLOGY', 'TELECOMMUNICATIONS', 'TRANSPORTATION'];
    const supported_ioe = ['API (REST / SOAP)', 'AUDIO VISUAL', 'BUILDING MANAGEMENT', 'DISTRIBUTED ANTENNA SYSTEMS', 'FTP / SFTP', 'HTTP / HTTPs', 'ICMP', 'IoT / IoE', 'PASSIVE OPTICAL NETWORKS', 'PROCESSES', 'NETWORKS & DEVICES', 'SECURITY SYSTEMS', 'SNMP', 'SSH', 'TELNET', 'UDP'];

    const solutions = [
        {
            cardName: 'industries',
            frontCard: renderIndustriesFront(),
            backText: renderBackCardText(industries),
            background: industries_card
        },
        {
            cardName: 'supportedioe',
            frontCard: renderSupportedIoeFront(),
            backText: renderBackCardText(supported_ioe),
            background: supportedioe_card
        }
    ];

    const solutions_headers = ['ACCELERATE PERFORMANCE', 'ANTICIPATE INTERRUPTION', 'EMBRACE COMPLEXITY', 'DISRUPT THE MARKET'];
    const virgil_statement = ['MONITOR', 'EVERYTHING'];
    const virgil_info = ['Meet the new norm', 'Virgil is an astute, attentive, mending platform, monitoring and measuring single or multiple things and sites', 'We go beyond traditional monitoring; collecting thousands of data points delivering aggregated intelligence into insightful proactive actionable widgets and notifications.'];

    const [is_card_flipped, setIsCardFlipped] = useState({
        industries: false,
        supportedioe: false
    });

    const handleHover = (e) => {
        setIsCardFlipped({[normalizeCaps(e.currentTarget.textContent)]: true});
    };
    const handleMouseLeave = (e) => {
        setIsCardFlipped({
            industries: false,
            supportedioe: false
        });
    };

    return (
        <>
            <Navigation/>

            <Grid container className={'solutions-container'}>
                <Grid container item xs={12} justifyContent={'flex-end'}>
                    {solutions_headers.map(el => (
                        <Grid item xs={12} key={el} style={{paddingRight: '20px'}}>
                            <Typography align={'right'} component={'h6'} variant={'h6'}
                                        classes={{root: classes.typographyFont, paragraph: classes.paragraph}}
                                        paragraph>
                                {el}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>

                <Grid container item xs={12} alignItems={'center'} justifyContent={'center'}>
                    <Grid item xs={12} md={6} classes={{root: classes.virgil}}>
                        <Box style={{width: '100%', textAlign: 'center'}}>
                            <img src={virgil_logo} alt={'Virgil logo'} className={'virgil-logo'}/>
                        </Box>

                        {virgil_statement.map(el => (
                            <Typography key={el} display={'block'} component={'h6'} variant={'h6'}
                                        classes={{root: classes.typographyFont}} style={{marginLeft: '10vw'}}>
                                {el}
                            </Typography>
                        ))}
                    </Grid>

                    {solutions.map(({cardName, frontCard, backText, background}) => (
                        <Grid item xs={8} sm={6} md={3} key={cardName}
                              classes={{root: classes.cards}}>
                            <ReactCardFlip isFlipped={is_card_flipped[cardName]}
                                           flipDirection={'horizontal'}>
                                <Card onMouseOver={handleHover} className={'card-solution'}
                                      style={{
                                          backgroundColor: 'var(--card-background)',
                                          color: 'var(--card-text)',
                                          backgroundImage: `url(${background})`,
                                          backgroundSize: 'contain',
                                          backgroundRepeat: 'no-repeat',
                                          backgroundPosition: 'center'
                                      }}>
                                    <Box style={{width: '100%'}}>
                                        <CardContent style={{padding: '0'}}>
                                            {frontCard}
                                        </CardContent>
                                    </Box>
                                </Card>

                                <Card onMouseLeave={handleMouseLeave} className={'card-solution'}
                                      style={{
                                          background: 'var(--card-background)',
                                          opacity: 0.8,
                                          color: 'var(--card-text)'
                                      }}>
                                    <CardContent>
                                        <Box>
                                            {backText}
                                        </Box>
                                    </CardContent>
                                </Card>
                            </ReactCardFlip>
                        </Grid>
                    ))}
                </Grid>

                <Grid container item xs={12}>
                    {virgil_info.map(el => (
                        <Grid item xs={12} key={el} classes={{root: classes.lastLine}}>
                            <Typography component={'h6'} variant={'h6'}
                                        classes={{root: classes.bottomTypography, paragraph: classes.paragraph}}
                                        paragraph>
                                {el}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </>
    );
};

export default Solutions;