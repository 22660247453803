import React from 'react';
import ComingSoon from './ComingSoon';
import Navigation from './Navigation';

const Support = () => {
    return (
        <>
            <Navigation/>
            <ComingSoon/>
        </>
    );
};

export default Support;