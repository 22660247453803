import React from 'react';
import {CssBaseline, Grid} from '@material-ui/core';
import logo from '../assets/img/logo/vigilantioe_logo.svg';
import Menu from './NavigationMenu';
import '../styles.css';

const Navigation = () => {
    return (
        <>
            <CssBaseline/>

            <Grid container className={'top'} justifyContent={'space-between'}>
                <Grid item xs={3}>
                    <a href={'/'}>
                        <img src={logo} alt={'logo'} className={'logo'}/>
                    </a>
                </Grid>
                <Grid item xs={8}>
                    <Menu/>
                </Grid>
            </Grid>
        </>
    );
};

export default Navigation;