import React from 'react';
import {Box, Grid, makeStyles, Typography} from '@material-ui/core';
import principles from '../assets/img/icons/guiding_principles.svg';
import Navigation from './Navigation';

const useStyles = makeStyles({
    typographyHeader: {
        fontFamily: 'var(--landing-font-family)',
        marginBottom: '5px',
        '@media screen and (max-width: 960px), (max-device-width: 960px)': {
            fontSize: '0.9rem'
        },
        '@media screen and (max-width: 600px), (max-device-width: 600px)': {
            fontSize: '0.8rem'
        },
        '@media screen and (max-device-width: 1500px) and (orientation: landscape)': {
            fontSize: '1rem'
        },
        '@media screen and (max-device-width: 850px) and (orientation: landscape)': {
            fontSize: '0.7rem'
        },
        '@media screen and (max-width: 420px), (max-device-width: 420px)': {
            fontSize: '0.6rem'
        }
    },
    typographyStatement: {
        fontFamily: 'var(--tabs-font-family)',
        fontWeight: 300,
        fontSize: '1.1rem',
        '@media screen and (max-width: 1280px), (max-device-width: 1280px)': {
            fontSize: '0.85rem'
        },
        '@media screen and (max-width: 600px), (max-device-width: 600px)': {
            fontSize: '0.75rem'
        },
        '@media screen and (max-width: 300px), (max-device-width: 300px)': {
            fontSize: '0.55rem'
        }
    },
    container: {
        marginTop: '10vh',
        '@media screen and (max-width: 960px), (max-device-width: 1280px)': {
            marginTop: '5vh'
        },
        '@media screen and (max-device-width: 650px)': {
            marginTop: '2vh'
        }
    },
    content: {
        marginBottom: '5vh',
        '@media screen and (max-width: 600px), (max-device-width: 600px)': {
            marginBottom: '2vh'
        }
    },
    textContainer: {
        marginBottom: '10vh',
        background: 'transparent',
        color: 'var(--text)',
        padding: '10px',
        '@media screen and (max-width: 600px), (max-device-width: 1100px)': {
            marginBottom: '5vh'
        }
    },
    bottomContainer: {
        textAlign: 'center',
        marginBottom: '5vh'
    },
    bottomText: {
        padding: '10px',
        '@media screen and (max-width: 400px), (max-device-width: 400px)': {
            fontSize: '0.6rem'
        }
    }
});

const Values = () => {
    const classes = useStyles();

    const statements = [
        {
            header: 'VISION STATEMENT',
            text: 'Create innovative next-gen software and solutions while providing exceptional support - connecting everyone and everything.'
        },
        {
            header: 'MISSION STATEMENT',
            text: 'Deliver innovative technology solutions and support that unite people, process and technology through a value-add-long-term strategic relationship.'
        }
    ];

    const guiding_principles = [
        {
            first: 'V',
            rest: 'ision'
        },
        {
            first: 'I',
            rest: 'nvaluable'
        },
        {
            first: 'G',
            rest: 'oals'
        },
        {
            first: 'I',
            rest: 'ntegrity'
        },
        {
            first: 'L',
            rest: 'ead'
        },
        {
            first: 'A',
            rest: 'ccountability'
        },
        {
            first: 'N',
            rest: 'avigate'
        },
        {
            first: 'T',
            rest: 'railblaze'
        },
        {
            first: 'I',
            rest: 'mpress'
        },
        {
            first: 'O',
            rest: 'utperform'
        },
        {
            first: 'E',
            rest: 'merge'
        }
    ];

    return (
        <>
            <Navigation/>

            <Grid container className={'grid-container'} classes={{root: classes.container}} justifyContent={'center'}>
                <Grid container item xs={12} justifyContent={'space-evenly'} classes={{root: classes.content}}>
                    <Grid container item xs={12} md={3} justifyContent={'center'}>
                        {statements.map(({header, text}) => (
                            <Grid container item xs={12} sm={6} md={12} key={header}
                                  classes={{root: classes.textContainer}} justifyContent={'center'}>
                                <Typography variant={'h6'} component={'h6'} display={'block'} align={'center'}
                                            classes={{root: classes.typographyHeader}}>
                                    {header}
                                </Typography>

                                <Typography display={'block'} align={'center'}
                                            classes={{root: classes.typographyStatement}}>
                                    {text}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>

                    <Grid container item xs={12} sm={6} md={3}>
                        <Grid container item xs={12} classes={{root: classes.textContainer}}>
                            <Grid item xs={12}>
                                <Typography variant={'h6'} component={'h6'} display={'block'} align={'center'}
                                            classes={{root: classes.typographyHeader}}>
                                    CORE VALUES
                                </Typography>

                                <Typography display={'block'} align={'center'}
                                            classes={{root: classes.typographyStatement}}>
                                    Cultivate culture where it's more than a word; it's a feeling turned to action.
                                    One of excellence where the runway is wide open for growth personally &
                                    professionally, and every teammate has a voice, contributes, exudes passion, and
                                    has fun.
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '20px'
                        }}>
                            <img src={principles} alt={'guiding_principles'} className={'principles-picture'}/>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} sm={6} md={3} justifyContent={'center'}>
                        <Typography variant={'h6'} component={'h6'} display={'block'} align={'center'}
                                    classes={{root: classes.typographyHeader}}>
                            GUIDING PRINCIPLES
                        </Typography>

                        {guiding_principles.map(({first, rest}) => (
                            <Grid container item xs={12} key={`${first}${rest}`} justifyContent={'center'}>
                                <Grid container item xs={6} justifyContent={'center'} alignItems={'baseline'}>
                                    <Grid item xs={4} sm={4} md={2}>
                                        <Box className={'principles-first'}>
                                            {first}
                                        </Box>
                                    </Grid>

                                    <Grid item xs={4} sm={4} md={2}>
                                        <Box key={rest} className={'principles-rest'}>
                                            {rest}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                <Grid container item xs={12} classes={{root: classes.bottomContainer}}>
                    <Grid item xs={12} classes={{root: classes.bottomText}}>
                        "You don't build a business - you build people - and then people build the
                        business" <span><i>Zig Ziglar</i></span>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Values;